import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Sms1 } from '../../../components/images/support/users/Sms1.generated';
import { Reass1 } from '../../../components/images/support/workflows/Reass1.generated';
import { Reass2 } from '../../../components/images/support/workflows/Reass2.generated';
import { Reass3 } from '../../../components/images/support/workflows/Reass3.generated';
import { Reass4 } from '../../../components/images/support/workflows/Reass4.generated';
import { Reass5 } from '../../../components/images/support/workflows/Reass5.generated';
import { Reass6 } from '../../../components/images/support/workflows/Reass6.generated';
import { Reass7 } from '../../../components/images/support/workflows/Reass7.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "re-assign-a-workflow-️"
    }}>{`Re-assign a Workflow ⚡️`}</h1>
    <p>{`Sometimes you may need to re-assign a workflow to a user.`}</p>
    <p>{`Common reasons for this include:`}</p>
    <ul>
      <li parentName="ul">{`To collect up to date information for a previously completed workflow.`}</li>
      <li parentName="ul">{`To recomplete a workflow that has incorrect information submitted.`}</li>
    </ul>
    <p>{`This guide shows how to re-assign a workflow two ways:`}</p>
    <ul>
      <li parentName="ul">{`Every task inside the workflow is re-completed`}</li>
      <li parentName="ul">{`Select certain tasks inside the workflow to be re-completed.`}</li>
    </ul>
    <p>{`Note that when tasks are completed multiple times Canyou will always synchronise the most up to date data
with Xero, MYOB and Deputy.`}</p>
    <p><strong parentName="p">{`Alternative option`}</strong>{`
`}<em parentName="p">{`Consider that if you only need a single task re-completed (for example a Tax File Number Declaration)
then you can create a new workflow for that single task and assign it to the user.`}</em></p>
    <p>{`Lets get started 🚀`}</p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Navigate to the users menu and click into the profile of the user you wish to re-assign the workflow to.`}</li>
    </ol>

    <Sms1 mdxType="Sms1" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click `}<strong parentName="li">{`Assign`}</strong>{`.`}</li>
    </ol>

    <Reass1 mdxType="Reass1" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Select the workflow you want to re-assign and click `}<strong parentName="li">{`Submit`}</strong>{`. Workflows only appear if there are no open tasks for that workflow on the users
profile. If there are still open tasks you can cancel the workflow or `}<a parentName="li" {...{
          "href": "support/tasks/skipping-tasks"
        }}>{`skip`}</a>{` the relevant tasks. `}</li>
    </ol>

    <Reass2 mdxType="Reass2" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`We can now see that the workflow has been re-assigned to the user. The user will now receive an email asking them to complete the workflow. That's it your all done 🙌.`}</li>
    </ol>
    <p>{`However if you only want the user to do certain tasks in this workflow then read on...`}</p>

    <Reass3 mdxType="Reass3" />
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Click into the workflow that you have just re-assigned`}</li>
    </ol>

    <Reass4 mdxType="Reass4" />
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Select the task the you `}<strong parentName="li">{`Do Not`}</strong>{` want the user to complete. Then click the menu in the top right and click `}<strong parentName="li">{`Skip`}</strong>{`. In this example we skip the bank account task.`}</li>
    </ol>

    <Reass5 mdxType="Reass5" />
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`We can now see that the task has been skipped. Now the user will not need to complete this task.`}</li>
    </ol>

    <Reass6 mdxType="Reass6" />
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Now navigate to the users profile again and you can see that 1 of the 7 tasks has been completed and now the user only has to complete the other 6.`}</li>
    </ol>

    <Reass7 mdxType="Reass7" />
    <h1 {...{
      "id": "great-work-you-have-now-re-assigned-a-workflow-"
    }}>{`Great work you have now re-assigned a workflow 🥳`}</h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      